import React from 'react';
import logo from "./data/logo.jpg"
import PayPal from "./data/PayPal.jpg"
import Zelle from "./data/Zelle.jpg"


const Footer = () => {
    return (
        <footer className="container-fluid text-center ">
            <div className="row">
            <div className="col-sm-4">
                   <div className="shoe-container">
                      <img src={logo} height={80} width={80} alt=""/>
                    </div>
                    <p>Counsel to Ark Salus has determined that donors cannot be held liable for the inherent risks of its mission. Further, Ark Salus is willing to explicitly release donors from liability upon request.</p>
                </div>
                <div className="col-sm-2">
                    <h4>Get in Touch</h4>
                    <p><span className="glyphicon glyphicon-map-marker"></span>10900 N.E. 4th Street,Suite 2300, Bellevue, Washington 98004</p>
                    <p><span className="glyphicon glyphicon-envelope"></span> rsk@arksalus.org</p>
                    
                </div>
                <div className="col-sm-2">
                    <h4>About Ark Salus</h4>
                    <p><span className="glyphicon "></span>  About Us</p>
                    <a href="https://www.instagram.com/arksalus/"><p><span className="glyphicon"></span> Instagram </p></a>
                    <a href="https://twitter.com/ArkSalus"><p><span className="glyphicon "></span>Twitter</p></a>
                    <a href="https://www.linkedin.com/in/ark-salus-aa622421a/"><p><span className="glyphicon "></span>Linkedin</p></a>
                </div>
                <div className="col-sm-4">
                    <h4>Pay Methods</h4>
                    <p><span className="glyphicon "></span>Account: rsk@arksalus.org</p>
                    <p><span className="glyphicon "></span>Pay Via:</p>
                    <p><a  href="https://www.zellepay.com/" className="btn btn-outline-secondary"><img src={PayPal} height={21} width={50} alt=""/></a></p>
                    <p><a href="https://www.paypal.com/us/webapps/mpp/campaigns/nna/signup-offer20m?kid=p67666540322&gclid=CjwKCAiA4veMBhAMEiwAU4XRr6pdMeEGGbmy3obhTX1dSS-1XrOuWFhtZJnDBqG_sH1YWg9h2ihAphoCe2wQAvD_BwE&gclsrc=aw.ds" className="btn btn-outline-secondary" ><img src={Zelle} height={25} width={50} alt=""/></a></p>
                </div>
           
          </div>
          <a href="#myPage" title="To Top">
                <span className="glyphicon glyphicon-chevron-up"></span>
            </a>
        </footer>
    )
}

export default Footer;