import React from 'react';
import About from "./data/About.jpg"

const Contact = () => {
    return (
        <div id="contact" className="container-fluid ">
            <div id="about" className="container-fluid">
            <h1 className="text-muted text-center" ><strong><span style={{color:"red"}}>C</span>ONTACT</strong></h1> 
               </div>
           
            <div className="row">
                
                <div className="col-sm-7 slideanim">
                    <div className="row">
                        <div className="col-sm-6 form-group">
                            <input className="form-control" id="name" name="name" placeholder="Name" type="text" required />
                        </div>
                        <div className="col-sm-6 form-group">
                            <input className="form-control" id="email" name="email" placeholder="Email" type="email" required />
                        </div>
                    </div>
                    <textarea className="form-control" id="Subject" name="Subject" placeholder="Suject" rows="1"></textarea><br />
                    <textarea className="form-control" id="messages" name="messages" placeholder="messages" rows="5"></textarea><br />
                    <div className="row">
                        <div className="col-sm-12 form-group">
                            <button className="btn btn-default pull-right" type="submit">Send</button>
                        </div>
                    
                    </div>
                    
                </div>
                <div className="col-sm-0.5"></div>
                <div className="col-sm-3" height="300px" weidth="100px">
                
                <img src={About} className="img-thumbnail card-img-top"  ></img>
                </div>
            </div>
        </div>
    )
}

export default Contact;