import React, { Fragment } from 'react';



const Payment = () => {
    return (
        <div id="payment" className="container-fluid  bg-grey ">
          <div  className="container-fluid ">
             <h1 className="text-muted text-center" ><strong><span style={{color:"red"}}>D</span>ONATE NOW</strong></h1>
               </div>
            <div className="slideanim">
            <div className="row card ">
            <div className="col-sm-3 form-group"></div>
            <div className="col-sm-3 form-group">
            <span><h4 className="why fade-in"><strong>Account :</strong> rsk@arksalus.org</h4> <h4><strong>     Ark Salus EIN :</strong> 87-2041194</h4></span>
            
            </div>
            <div className="col-sm-1">
            <h3>Pay Via:</h3>
            </div>
            <div className="col-sm-5" >
                <a href="https://www.zellepay.com/" className="btn btn-outline-secondary" height={50} width={90}><img src="https://www.bankmw.com/wp-content/uploads/2020/06/Zelle-logo-no-tagline-RGB-purple.png" height={50} width={90} alt=""/></a>
                <a href="https://www.paypal.com/us/webapps/mpp/campaigns/nna/signup-offer20m?kid=p67666540322&gclid=CjwKCAiA4veMBhAMEiwAU4XRr6pdMeEGGbmy3obhTX1dSS-1XrOuWFhtZJnDBqG_sH1YWg9h2ihAphoCe2wQAvD_BwE&gclsrc=aw.ds" className="btn btn-outline-secondary" height={50} width={90}><img src="https://www.paymentscardsandmobile.com/wp-content/uploads/2021/01/paypal-logo.jpg" height={50} width={90} alt=""/></a>
              </div>
             </div>   
            <div className="row border">
            <div className="check card" >
                <div className=" row card-body">
               
               
               
               <div className="row">
               <div className="col-sm-2 form-group"></div>
               <div className="col-sm-9 form-group">
               <h2>Via Check</h2>  
               <h5>Please make all checks payable to "ARK SALUS"</h5> 
            
               
               <div className="row ">
                   
                        <div className="col-sm-4 form-group text-left">
                <div className="text-left">
                        <h4>Mailing Address:</h4>         
               <p>Ark Salus</p>
               <p>10900 N.E. 4th Street</p>
               <p>Suite 2300, Bellevue</p>
               <p>Washington 98004</p>
               </div>
                        </div>
                        <div className="col-sm-4 form-group">
               <div className="text-left">       
               <h4>East Coast Office Address:</h4>
               <p>Ark Salus</p>
               <p>1655 North Fort Mayer Drive, Suite 700</p>
               <p>Arlington, VA, 22209</p>
               </div>
                        </div>
                        <div className="col-sm-4 form-group text-left">
                        <div className="text-left">
                       <h4>Canada:</h4>
                       <p>Vector Global Solutions</p>
                       <p>535 Yates Street,</p>
                       <p>Victoria, British Columbia</p>
                       <p>V8W 2Z6</p>
                       </div>
                                </div>
                    </div>
                   
                    </div>
                </div>
            </div>
            </div>

           </div>
               
                </div>
            </div>
            
            
            
        
       
    )
};

export default Payment;