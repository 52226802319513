import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Why from './components/Why';

import Portfolio from './components/Portfolio';

import Contact from './components/Contact';
import Footer from './components/Footer';
//import Imageblock from './components/Imageblock'
import Gallery1 from "./components/gallery1"
import Payment from "./components/Payment"
//import Highlights from "./components/Highlights"
//import Team from "./components/Team"
//import Team1 from "./components/Team1"



function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero/>
      
      <About />
      <Why/>
      <Payment/>
      <Gallery1/>
      
      <Portfolio />
      <Contact />
      <Footer />
    
    </div>
  );
}

export default App;
