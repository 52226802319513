import React from 'react';

const Portfolio = () => {
    return (
        <div id="portfolio" className="container-fluid text-center ">
             <div  className="container-fluid ">
             <h1 className="text-muted " ><strong><span style={{color:"red"}}>N</span>EWS</strong></h1>
               </div>
            
           
            <div className="row text-center slideanim">
                <div className="col-sm-4">
                    <div className="thumbnail">
                        <img src="https://coffeeordie.com/wp-content/uploads/2021/08/180513-cod-kabul-specialmissionwing-37-scaled.jpg" className="image_url_300height" alt="Paris" width="400px" height="300px" max-height= "225px" />
                        <a href="https://coffeeordie.com/help-afghan-special-operators/"><p><strong>HOW WE CAN HELP AFGHAN SPECIAL OPERATORS FIGHT THE TALIBAN</strong></p></a>
                        
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="thumbnail">
                        <img style={{height: "285px"}} src="https://smestreet.in/wp-content/uploads/2021/08/Ark-Salus-Afghanistan-800x600.jpeg" alt="New York" className="img-fluid img-thumbnaik" />
                        <a href="https://smestreet.in/limelight/ark-salus-an-humanitarian-initiative-to-help-afghans/"><p><strong>Ark Salus: Humanitarian Initiative to Help Humanity in Afghanistan</strong></p></a>
                        
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="thumbnail">
                        <img src="https://www.ncronline.org/sites/default/files/styles/article_full_width/public/20210824T1215-AFGHANISTAN-CONFLICT-1506806.jpg?itok=2qzlFnS5" className="thumbnail" alt="San Francisco" width="400px" height="300px" />
                        <a href="https://abcnews.go.com/ThisWeek/video/theyre-yelling-lives-army-ranger-assisted-afghan-evacuations-79709098?fbclid=IwAR1GoYwFOiLYYTVEQl3ndAnUagtjroRB4xM_SzHCiG1afz_uwgLD7sSbDA8"><p><strong>'They're yelling for their lives': Former Army Ranger who assisted Afghan evacuations</strong></p></a>
                        
                    </div>
                </div>
            </div><br />
            
        </div>

    )
}

export default Portfolio;