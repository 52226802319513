import React from 'react';
import imageblock from "./data/Background.jpg" 
const Hero = () => {
    return (
        <div>
        <div className="jumbotron text-center">
            <h1 className="header mb-3"><strong>Ark Salus</strong></h1>
            <h3 className="caption"><strong>Noah's <span style={{color:"red"}}>"Ark"</span><br/>Salus is latin for <span style={{color:"red"}}>"Safe" </span><br/><br/></strong></h3>
            <div className="w-55 p-10"><a className="btn btn-danger" href="#payment"  >DONATE NOW</a></div>
   
        </div>
        
        </div>
    )
}

export default Hero;