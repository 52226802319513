import React from "react";
import logo from "./data/logo.jpg";

const Navbar = () => {
  return (
    <nav className="navbar navbar-default navbar-fixed-top">
      <a className="arklogo logo navbar-brand" href="#myPage">
        {" "}
        <img src={logo} height={85} width={99} alt="" />{" "}
      </a>{" "}
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target="#myNavbar"
          >
            <span className="icon-bar"> </span>{" "}
            <span className="icon-bar"> </span>{" "}
            <span className="icon-bar"> </span>{" "}
          </button>
        </div>{" "}
        <div className="collapse navbar-collapse" id="myNavbar">
          <ul className="nav navbar-nav navbar-right">
            <li>
              {" "}
              <a href="#myPage" className="btn btn-light">
                {" "}
                ABOUT{" "}
              </a>
            </li>

            <li>
              {" "}
              <a href="#gallery" className="btn btn-light">
                {" "}
                MEDIA{" "}
              </a>
            </li>
            <li>
              {" "}
              <a href="#portfolio" className="btn btn-light">
                {" "}
                NEWS{" "}
              </a>
            </li>
            <li>
              {" "}
              <a href="#team" className="btn btn-light">
                {" "}
                TEAM{" "}
              </a>
            </li>
            <li>
              {" "}
              <a href="#contact" className="btn btn-light">
                {" "}
                CONTACT{" "}
              </a>
            </li>
            <li>
              {" "}
              <a className="btn btn-danger" href="#payment">
                {" "}
                DONATE NOW{" "}
              </a>
            </li>
          </ul>{" "}
        </div>{" "}
      </div>{" "}
    </nav>
  );
};

export default Navbar;
