import React, { Fragment } from 'react';
//import bar from './data/About.jpg';


const About = () => {
    return (
        <Fragment>

           

            <div className="container-fluid bg-grey ">
                <div className="row">
                    <div className="col-sm-3">
                        <span style={{color:"green"}} className="glyphicon glyphicon-globe logo green slideanim"></span>
                    </div>
                    
                    <div className="col-sm-9">
                    <h1 className="text-muted" ><strong><span style={{color:"red"}}>W</span>HO WE ARE</strong></h1>
                    <br/>
                        <p  className="why fade-in">Ark Salus is a Washington state non profit humanitarian organization, 501(c)(3) approved. We are in a mission to assist people afflicted by conflict or disaster with an emergency relief. Reunification of families. Helping women and children in the disaster zones.. </p><br />
 
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default About;
