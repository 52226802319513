import React, { Fragment } from 'react';
//import bar from './data/About.jpg';
import Highlights1 from "./data/h1.jpg"
import Highlights2 from "./data/h2.jpg"
import Highlights3 from "./data/h3.jpg"


const Why = () => {
    return (
        <Fragment>

           

            <div className="container-fluid">
                <div className="row">
                    
                    <div className=" why col-sm-10" >
                    <h1 className="text-muted" ><strong><span style={{color:"red", textalign: "justify"}}>W</span>HY Ark Salus</strong></h1>
                    <br/>
                        <p>Ark Salus is a humanitarian organization assisting people in disasters, reunification of families, educating families, helping children and women from the disasters. Ark Salus, a non profit 501(c)(3) approved organization that  has successfully evacuated over 600 Afghans from Kabul in August 2021, helped over 700 families in food assistance and engaged with the families for reaching their loved ones in Afghanistan and other countries. Ark Salus has taken a mission to help Women, Children, Journalists, Doctors, Engineers, Social workers, students, ex-government officials, Afghan Diplomats, Military & Veteran families and many more injured families. In need of support from public to help thousands of Afghan families. They need help of Public to eat, live and be safe.

Ark Salus is working to help Ukrainians and also give a Humanitarian support to Ukrainians in war zone areas of Ukraine, looking for public help for supplies, food, medicines, clothes, financial help to the families. Immigration support and assistance is needed for Millions of Ukrainians.

Ark Salus values and thankful to every person who have donated or volunteered to help the Afghans and Ukrainians. Without Public support and givings we do not exist.

Our motto is “Save the people and families in disasters”  Helping humans is being human.

Ark Salus- 501(c)(3) Approved non-profit Organization.</p>
                    </div>
                    <div className="col-sm-2">
                        <br/>
                        <br/>
                        <br/>
                        <span style={{color:"green"}} className="glyphicon glyphicon-signal logo slideanim green"></span>
                    </div>
                    
                </div>
            <br/>
            <br/>

            <div className="row">
                
            <div className="col-sm-4 slideanim">
                <img src={Highlights1} className="img-thumbnail"></img>
            </div>
            <div className="col-sm-4 slideanim">
                <img src={Highlights2} className="img-thumbnail"></img>
            </div>
            <div className="col-sm-4 slideanim">
                <img src={Highlights3} className="img-thumbnail"></img>
            </div>
        </div>
                
            </div>
        </Fragment>
    )
};

export default Why;
